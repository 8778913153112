import Service from "../Service";
import Vue from "vue";
const resource = "Layette/"

export default {

    GetLayetteFormed(obj, requestID) {
        return Service.post(resource + "GetLayetteFormed", obj, {
            params: { requestID: requestID },
        });
    },

    GetPallet(obj, requestID) {
        return Service.post(resource + "GetPalletUSA", obj, {
            params: { requestID: requestID },
        });
    },

    GetLayette(requestID) {
        return Service.post(resource + "GetLayette", "", {
            params: { requestID: requestID },
        });
    },

    GetLayetteDetail(obj, requestID) {
        return Service.post(resource + "GetLayetteDetail", obj, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    saveRework(obj, requestID) {
        return Service.post(resource + "saveRework", obj, {
            params: { requestID: requestID },
        });
    },

    saveFrozen(obj, requestID) {
        return Service.post(resource + "saveFrozen", obj, {
            params: { requestID: requestID },
        });
    },

    listLayettePosition(obj, requestID) {
        return Service.post(resource + "listLayettePosition", obj, {
            params: { requestID: requestID },
        });
    },

    updatePosition(obj, requestID) {
        return Service.post(resource + "updatePosition", obj, {
            params: { requestID: requestID },
        });
    },
    lytDetailonfloor(lytID, requestID) {
        return Service.post(resource + "lytDetailonfloor", {}, {
            params: { lytID: lytID, requestID: requestID },
        });
    },
    lytonfloor(requestID) {
        return Service.post(resource + "lytonfloor", {}, {
            params: { requestID: requestID },
        });
    },
    lytonfloorPickUp(obj, requestID) {
        return Service.post(resource + "lytonfloorPickUp", obj, {
            params: { requestID: requestID },
        });
    },
    lytDetailTableonfloor(obj, requestID) {
        return Service.post(resource + "lytDetailTableonfloor", obj, {
            params: { requestID: requestID },
        });
    },
    /*
       pagination(obj, requestID) {
           return Service.post(resource + "pagination", obj, {
               params: { requestID: requestID }
           });
       }, */

};