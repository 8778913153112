import Service from "../Service";

const resource = "createlayette/"

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
          params: { requestID },
        });
      },

    save(lay, requestID) {
        return Service.post(resource + 'save', lay, {
            params: { requestID }
        });
    },

    list(requestID) {
        return Service.post(resource + "list", {}, {
            params: { requestID }
        });
    },

    getrowcolumncooling(requestID) {
        return Service.post(resource + "getrowcolumncooling", {}, {
            params: { requestID }
        });
    },
    //Para llenar la grilla y editar
    llenarParihuela(LayID, requestID){
        return Service.post(resource + "listParihuela",{},{
            params:{LayID:LayID, requestID:requestID}
        });
    }
}