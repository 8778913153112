<template>

  <v-card
    color="#DFF0F8"
    width="180"
	height="auto"
	class="my-1 ml-1"
	v-if="layette"
  >
	<!-- max-width="400" 
	class="mx-auto"-->
    <v-card-title class="justify-center">
      <v-icon
        large
        left
      >
        mdi-domain
      </v-icon>
      <span>Tratamiento {{layette.TnkNumberTreatment}}</span>
    </v-card-title>

    <v-card-text class="text-center" style="margin-top: -10px" >
		<span >Hora Salida:</span><br>
		<span>{{(new Date(layette.LytDateEnd)).toLocaleString()}}</span>
		<v-btn
			style="margin-top: -3px"
			class="mx-2"
			fab
			dark
			small
			:color="layette.TypeCoolingStatusColor"
			@click="showModalDetail(layette.LytID)"
		>
			<i :class="layette.TypeCoolingStatusIcon"></i>
		</v-btn>
		<br>
		<span>{{this.countHour}}</span>
    </v-card-text>

    <v-card-actions>
		<v-col style="margin-top: -10px">
			<v-row class="justify-center">
				<!--<countdown @updateLayette="updateLayette($event)" :cooling="cooling" :item="layette" @changeCooling="changeCooling"></countdown>
			--></v-row>
			<!-- <v-row class="justify-center">
				<v-btn
					depressed
					color="primary"
					small
					@click="pickUpLayette"
				>
					<i class="fas fa-truck-pickup"></i>
				</v-btn>
			</v-row> -->
		</v-col>
		
    </v-card-actions>

	<!-- <pick-up-observation :dialog="dialog" @hideModal="hideModal" @sendObservation="sendObservation($event)"></pick-up-observation> -->

	<v-dialog
			v-model="modalLytDetail"
			width="1000"
			height="800"
			persistent
 
		>
		<v-card>
			<s-toolbar  
            color="primary" 
            dark
            close
            @close="modalLytDetail = false"
            label="Listados de Palet"
        >
        </s-toolbar>
			<v-row style="margin: auto">
				<v-col cols="12">
					<v-data-table
						dense
						:items="ItemLytDetail"
						:headers="headerItemLytDetail"
						:items-per-page="-1"
						hide-default-footer
						disable-sort
					>
						<template v-slot:item.accion="{ item }">
							<v-btn
								depressed
								color="primary"
								small
								@click="pickUpLayette(item)"
							>
								<i class="fas fa-truck-pickup"></i>
							</v-btn>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
			</v-card>
		</v-dialog>
  </v-card>


  <v-card
    color="#DFF0F8"
    width="180"
	height="180"
	class="my-1 ml-1"
	v-else
  >
    <v-card-title class="justify-center">
      <v-icon
        large
        left
      >
        mdi-domain
      </v-icon>
      <span>Pallet Vacia</span>
    </v-card-title>

    <v-card-text class=text-center>
    </v-card-text>

    <v-card-actions>
		
    </v-card-actions>

  </v-card>

</template>

<script>

import _sLayetteService from "@/services/FreshProduction/PrfLayetteService.js";
 /*import Countdown from '@/components/FreshProduction/BesketMap/Countdown.vue'; 
import PickUpObservation from './PickUpObservation.vue'; */
  export default {
  /* components: { Countdown }, , PickUpObservation*/

	props: {
		layette: {
			type: Object,		
		}
	},

    data: () => ({
		nIntervId: null,
		modalLytDetail: false,
		ItemLytDetail: [],
		cooling: true,
		dialog: false,
		observation: "",
		headerItemLytDetail: [
			{ text: "Cod. Palet", value: "LlpID", width: 160 },
			{ text: "Cantida de Jabas", value: "LlpQuantityJaba", width: 160 },
			{ text: "Cultivo", value: "TypeCultiveName", width: 160 },
			{ text: "Calibre", value: "TypeCaliberName", width: 160 },
			{ text: "Lote", value: "RcfID", width: 160 },
			{ text: "Certificacion", value: "TypeCertificationName" },
			{ text: "Acción", value: "accion" },
		],
		countHour: "00:00:00"
    }),
	methods: {
		changeCooling()
		{
			this.cooling = false;
		},

		updateLayette(item)
		{
			this.$emit('updateLayette', item);
		},

		/* sendObservation(item)
		{
			this.observation = item;
			this.layette.CoolingStatus = 3;
			this.layette.LayObservation = this.observation;
			this.$emit('updateLayette', this.layette);
			this.$fun.alert('Canastilla recogida', 'success');
		}, */

		pickUpLayette(item)
		{
			this.$fun.alert('Seguro de recoger?', 'question')
			.then(resp => {
				if(resp.value){
					this.layette.CoolingStatus = 3;
					this.layette.LayObservation = this.observation;
					
					//this.$fun.alert('Canastilla recogida', 'success');
					
					let obj = {
						LytID : this.layette.LytID,
						LlpID : item.LlpID
					}
					console.log('objj ', this.layette);

					_sLayetteService.lytonfloorPickUp(obj, this.$fun.getUserID())
					.then((r) => {
						if(r.status == 200){
							this.$fun.alert('Pallet recogida', 'success');
							this.$emit('PickUp', this.layette);
							this.showModalDetail(this.layette.LytID);
						}
					}); 
					
					this.dialog = true;
					//este si sirve lo de abajo solo que lo comente para que no se actualice
					this.layette.CoolingStatus = 3;
					this.layette.observation = this.observation;
					this.$emit('updateLayette', this.layette);
				}
			});
		},

		hideModal()
		{
			this.dialog = false;
		},
		/* updateCooling() {
			setInterval(() => {
				this.cooling = false;
			}, 1000);
		} */
		showModalDetail(lytId){
			_sLayetteService.lytDetailonfloor(lytId, this.$fun.getUserID())
			.then((r) => {
				if (r.status == 200) {
					this.ItemLytDetail = r.data;
					console.log('ddddddddddddd ', this.ItemLytDetail);
				}
			})

			this.modalLytDetail = true;
		},
		msToTime(duration) {
			var milliseconds = parseInt((duration % 1000) / 100),
				seconds = Math.floor((duration / 1000) % 60),
				minutes = Math.floor((duration / (1000 * 60)) % 60),
				hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

			hours = (hours < 10) ? "0" + hours : hours;
			minutes = (minutes < 10) ? "0" + minutes : minutes;
			seconds = (seconds < 10) ? "0" + seconds : seconds;

			//return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
			return hours + ":" + minutes + ":" + seconds;
		},
		changeHour(){
			let time = this;
		 	this.nIntervId = setInterval(()=>{
				time.layette.TypeCoolingStatusIcon = "fas fa-fire";
				time.layette.TypeCoolingStatusColor = "pink";

				let count = Math.abs((new Date().getTime())-(new Date(time.layette.DateEndCooling).getTime())) ;

				if ( (new Date()) >= (new Date(time.layette.DateEndCooling))) {
					time.layette.TypeCoolingStatusIcon = "fas fa-cube";
					time.layette.TypeCoolingStatusColor = "cyan";
					time.countHour = "00:00:00";
				}else{
					time.countHour = time.msToTime(count)
				}

				console.log("time.countHour", time.countHour)
			}, 1000);			
		}
	},
	created () {
		console.log("layetteeeeeeeeeeeeeeeeee",this.layette);
		/* console.log('ddd', this.layette); */
		/* setInterval(() => {
				this.cooling = false;
			}, 6000); */
	},
	mounted(){
 
		this.changeHour();
	},
	beforeDestroy(){
		clearInterval(this.nIntervId);
		// liberar nuestro inervalId de la variable
		this.nIntervId = null;
	}
  }
</script>