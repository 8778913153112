<template>
	<v-card>
		<v-container>			
			<v-row>
				<v-col lg="12" sm="12" md="12">
					<alert-supplies v-if="hasSuppliesNotRecommended" :FltID="FltID" :listSupplies="listSupplies"></alert-supplies>
					
					<v-container>
						<v-row>
							<!--v-for="item in layettes" :key="item.lytID"-->
							<!--   align="center" cols="12" lg="12" md="12" -->
							<v-col v-for="item in layettes" :key="item.lytID">
								
									<card-cooling-pallet  
										:layette="item"
										@PickUp="PickUp($event)">
									</card-cooling-pallet>
							
								<!--<drag-drop
									:dropzones="columns"
									:originalData="layettes"
									:inPlace="true"
									:enableSave="true"
									:dropzonesTitle="'UBICACIÓN DE CANASTILLA'"
									>
									<template #dd-card="{ cardData }">
									<div class="cc-card">
										{{cardData}}
									</div>
										<card-cooling-pallet  :layette="cardData" ></card-cooling-pallet>
									</template>
								</drag-drop>-->
							</v-col>
						</v-row>
					</v-container>

				</v-col>
			</v-row>
		</v-container>	
	</v-card>
</template>

<script>
import CardCoolingPallet from './CardCoolingPallet.vue'
import _sAlertHistoryService from "@/services/FreshProduction/AlertHistoryService";
import AlertSupplies from '../../../components/FreshProduction/FreshReception/AlertSupplies.vue';
import _sLayetteCreateService from '../../../services/FreshProduction/CreateLayetteService';
import _sLayetteService from "@/services/FreshProduction/PrfLayetteService.js";
 
export default {
	components: {
		CardCoolingPallet,
		AlertSupplies,
	},

	data() {
		return {
			hasSuppliesNotRecommended: false,
			listSupplies: [],
			FltID: 1085,
			rows: 0,
			columns: 6,
			layettes: []
		}
	},

	methods: {
		getRowColumnas()
		{
			/* _sLayetteCreateService.getrowcolumncooling(this.$fun.getUserID())
			.then((resp) => {
				if(resp.status == 200){
					let data = resp.data;
					this.rows = parseInt(data[0].count);
					this.columns = parseInt(data[1].count);
					console.log('rowww ', data);
				}
			}); */
		},
		getLayetts(){
			_sLayetteService.lytonfloor(this.$fun.getUserID())
				.then((r) => {
					console.log(r);
					if (r.status == 200) {
						
						this.layettes = r.data;
						console.log('sssssssssssssssssssss ', this.layettes);
					}
				});
		},
		PickUp(item){
			console.log("item",item);
			this.getLayetts();
		}  

	},

	created () {
		this.getRowColumnas();

		 
	},

	watch: {
	},
	mounted(){
		this.getLayetts();
	},
}
</script>